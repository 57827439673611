import React, { useState, useEffect } from 'react'
import * as Routes from 'routes'

import FlashType from './FlashType'
import Session from './Session'
import User from './User'

import HeaderNavbarNav from './HeaderNavbarNav'
import HeaderNavbarNavForUser from './HeaderNavbarNavForUser'
import { Flash } from './Flash'

interface HeaderProps {
  flash?: FlashType;
}

const Header: React.FC<HeaderProps> = props => {
  const [flash, setFlash] = useState<FlashType>(props.flash)
  const [user, setUser] = useState<User>()

  useEffect(() => {
    void $.ajax({
      type: 'GET',
      url: Routes.session_path(),
      dataType: 'json',
      success: (json: Session) => {
        const event = new CustomEvent('session:fetched', { detail: { session: json } })
        document.dispatchEvent(event)
        setUser(json.user)
        if (json.flash) {
          const { success, notice, alert, error } = json.flash
          setFlash({
            success: (success || flash?.success),
            notice: (notice || flash?.notice),
            alert: (alert || flash?.alert),
            error: (error || flash?.error),
          })
        } else {
          setFlash({})
        }
      },
    })
  }, [])

  return (
    <>
      <div className="header-navbar header-navbar-fv" id="navbarSupportedContent">
        <a href={Routes.root_path()} className="header-navbar-brand" data-turbo="false" />
        <div className="header-navbar-nav">
          {user ? <HeaderNavbarNavForUser user={user} /> : <HeaderNavbarNav />}
        </div>
      </div>
      {flash ? <Flash flash={flash} /> : null}
    </>
  )
}
export default Header
