import axios, { AxiosResponse, AxiosError } from 'axios'
import * as V1Api from './generated'

const baseURL = window.location.origin
const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  responseType: 'json',
})

function isBadRequestError(response: AxiosResponse):
  response is AxiosResponse<V1Api.BadRequestError>
{
  return response.status === 400
}

function isUnauthorizedError(response: AxiosResponse):
  response is AxiosResponse<V1Api.UnauthorizedError>
{
  return response.status === 401
}

function isNotFoundError(response: AxiosResponse):
  response is AxiosResponse<V1Api.NotFoundError>
{
  return response.status === 404
}

function isAxiosError(error: any): error is AxiosError {
   
  return axios.isAxiosError(error)
}

const API = {
  ...V1Api.V1ApiFactory(undefined, baseURL, axiosInstance),
  isBadRequestError,
  isUnauthorizedError,
  isNotFoundError,
  isAxiosError,
}

export { API, axiosInstance }
